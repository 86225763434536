<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Validasi Pendaftaran View</h6>
        </b-col>
        <b-col lg="6" cols="7" style="display:flex;justify-content:flex-end">
          <div style="position:fixed;z-index:100;">
            <div style="display: flex; justify-content: flex-end;background:#29664A;padding:10px;border-radius:5px;box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);"> 
              <base-button  @click="back()">Kembali</base-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </base-header>

    <b-container v-if="isLoading">
      <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
    </b-container>

    <b-container fluid class="mt--5" v-if="!isLoading">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <h6 class="surtitle">Overview</h6>
              <h5 class="h3 mb-0">Akun</h5>

              <div class="card-body">
                <ul class="list-group">
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Nama Kontak Person</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.user.name }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Nomor Handphone</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.user.phone }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Email Kontak Person</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.user.email }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </b-card>
        </b-col>

        <!-- umum -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <h6 class="surtitle">Overview</h6>
              <h5 class="h3 mb-0">Umum</h5>

              <div class="card-body">

                <ul class="list-group">
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Nama Perusahaan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>ID Pendaftaran</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.uid }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Jenis Industri</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.company_type.name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item" v-if="
                    listData.company.company_type.name === 'Pertambangan Emas dan Tembaga' ||
                    listData.company.company_type.name === 'Pertambangan Batu Bara' ||
                    listData.company.company_type.name === 'Pertambangan Nikel'
                  ">
                    <div>
                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <strong>Status Tambang</strong>
                    </div>
                    <div class="col-lg-9">
                      <span>{{ listData.company.company_mine_data.mine_status }}</span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <strong>Jenis Kegiatan</strong>
                    </div>
                    <div class="col-lg-9">
                      <span>{{ listData.company.company_mine_data.activity_type }}</span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <strong>Rencana penetapan titik penaatan</strong>
                    </div>
                    <div class="col-lg-9">
                      <a
                        :href="
                          listData.company.company_mine_data.complience_point
                            ? listData.company.company_mine_data.complience_point
                            : ''
                        "
                        target="blank"
                      >
                        <span>
                          <i class="ni ni-cloud-download-95 text-primary"></i>
                        </span>
                        <span>{{
                          listData.company.company_mine_data.complience_point === "" ||
                          listData.company.company_mine_data.complience_point === undefined ||
                          listData.company.company_mine_data.complience_point === null
                            ? " Belum ada  File Terupload"
                            : " File Terupload"
                        }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <strong
                        >Kondisi jaringan internet/GSM pada lokasi remote
                        area</strong
                      >
                    </div>
                    <div class="col-lg-9">
                      <span v-html="listData.company.company_mine_data.network_status"></span>
                    </div>
                  </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Nama Penanggung Jawab SIUP</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.siup_on_behalf }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Lampiran SIUP</strong>
                      </div>
                      <div class="col-lg-9">
                        <a
                            :href="
                              listData.company.siup_attachment
                                ? listData.company.siup_attachment
                                : ''
                            "
                            target="blank"
                          >
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              listData.company.siup_attachment === "" ||
                              listData.company.siup_attachment === undefined ||
                              listData.company.siup_attachment === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Surat Permohonan Koneksi</strong>
                      </div>
                      <div class="col-lg-9">
                        <a
                            :href="
                              listData.company.connection_request
                                ? listData.company.connection_request
                                : ''
                            "
                            target="blank"
                          >
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              listData.company.connection_request === "" ||
                              listData.company.connection_request === undefined ||
                              listData.company.connection_request === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                      </div>
                    </div>
                  </li>
                  
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Alamat Perusahaan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.address }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Jalan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.street }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Kabupaten / Kota</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.regency.name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Provinsi</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.province.name }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Telepon Kantor</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.phone }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Email Kantor</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.company.email }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </b-card>
        </b-col>


        <!-- identifikasi Sumber pencemaran  DONE -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <h6 class="surtitle">Overview</h6>
              <h5 class="h3 mb-0">Indentifikasi Sumber Pencemaran</h5>
              <!-- PERMIT -->
              <div id="teknis" class="card">
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Izin Pembuangan Air Limbah ke Media Air</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="listData.company.company_document_file_permit.file" target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              listData.company.company_document_file_permit.file === "" ||
                              listData.company.company_document_file_permit.file === undefined ||
                              listData.company.company_document_file_permit.file === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Pejabat Penerbit Izin</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.company_document_file_permit.name }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong
                            >Nomor Izin Pembuangan Air Limbah (Definitif)</strong
                          >
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.company_document_file_permit.number}}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Tanggal Izin Terbit</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ formatDate(listData.company.company_document_file_permit.date_permit_issuance) }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Berlaku</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ formatDate(listData.company.company_document_file_permit.date_permit_expired) }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Sumber Air Limbah</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.polluted_sources[0].waste_water_source }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah Titik Penaatan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.polluted_sources[0].number_compliance_point_num }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item" style="background-color: #eaeaea;">
                      <div
                        v-for="(spot, index) in listData.polluted_sources[0].number_compliance_point_data"
                        :key="spot.name"
                        class="mb-5"
                      >
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-lg-3">
                              <strong>Nama Titik Penaatan {{ index + 1 }}</strong>
                            </div>
                            <div class="col-lg-9">
                              <span>{{ spot.name }}</span>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-lg-3">
                              <strong>Lintang</strong>
                            </div>
                            <div class="col-lg-9">
                              <span>{{ spot.latitude }}</span>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-lg-3">
                              <strong>Bujur</strong>
                            </div>
                            <div class="col-lg-9">
                              <span>{{ spot.longitude }}</span>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-lg-3">
                              <strong>Nama Badan Air Penerima Air Limbah</strong>
                            </div>
                            <div class="col-lg-9">
                              <span>{{ spot.waste_water_receiving_agency }}</span>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-lg-3">
                              <strong>DAS</strong>
                            </div>
                            <div class="col-lg-9">
                              <span>{{ spot.das }}</span>
                            </div>
                          </div>
                        </li>
                      </div>
                    </li>
                  </ul>






                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Jenis Teknologi Pengolahan Air Limbah</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.polluted_sources[0].type_waste_water_treatment_technology }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Jenis Air Limbah</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.polluted_sources[0].type_waste_water }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Debit</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.polluted_sources[0].debit_value }} {{ listData.polluted_sources[0].debit_unit }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Kapasitas Produksi Sesuai Izin</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.polluted_sources[0].actual_production_capacity }}</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Kapasitas Produksi Senyatanya</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.polluted_sources[0].actual_production_capacity }}</span>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>

        <!-- Frequensi Pembuangan air limbah DONE --> 
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <h6 class="surtitle">Overview</h6>
              <h5 class="h3 mb-0">Frekuensi pembuangan air limbah</h5>

              <div class="card">
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Frekuensi Pembuangan Air Limbah</strong>
                        </div>
                        <div class="col-lg-9">
                          <span v-html="listData.polluted_sources[0].frequency_disposal.background">
                          </span>
                        </div>
                      </div>
                    </li>

                    <!-- fix -->
                    <li class="list-group-item" v-if="listData.polluted_sources[0].frequency_disposal.type === 'Tidak Rutin/Intermiten'">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Latar Belakang</strong>
                        </div>
                        <div class="col-lg-9">
                          <span v-html="listData.polluted_sources[0].frequency_disposal.background"></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Detail Engineering IPAL</strong>
                        </div>
                        <div class="col-lg-9">
                          <span v-html="listData.polluted_sources[0].frequency_disposal.ipal_detail_engineering"></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Foto IPAL</strong>
                        </div>
                        <div class="col-lg-9">
                          <a
                            :href="listData.polluted_sources[0].frequency_disposal.ipal_photo"
                            target="blank"
                          >
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              listData.polluted_sources[0].frequency_disposal.ipal_photo === "" ||
                              listData.polluted_sources[0].frequency_disposal.ipal_photo === undefined ||
                              listData.polluted_sources[0].frequency_disposal.ipal_photo === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Metode Pengukuran Debit</strong>
                        </div>
                        <div class="col-lg-9">
                          <span v-html="listData.polluted_sources[0].frequency_disposal.measurement_method"></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Logbook Pembuangan Air Limbah</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="listData.polluted_sources[0].frequency_disposal.waste_water_disposal_logbook" target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              listData.polluted_sources[0].frequency_disposal.waste_water_disposal_logbook === "" ||
                              listData.polluted_sources[0].frequency_disposal.waste_water_disposal_logbook === undefined ||
                              listData.polluted_sources[0].frequency_disposal.waste_water_disposal_logbook === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Per hari</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{listData.polluted_sources[0].frequency_disposal.day + " jam" }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Per minggu</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.polluted_sources[0].frequency_disposal.month + " hari" }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Per tahun</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.polluted_sources[0].frequency_disposal.year + " bulan" }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>

        <!-- Persyaratan teknis DONE -->
        <b-col xl="12">
          <b-card>
            <template v-slot="header">
              <h6 class="surtitle">Overview</h6>
              <h5 class="h3 mb-0">Persyaratan Teknis</h5>


              <div id="validitas" class="card" style="background-color: #eaeaea;">
                <div class="card-body" v-for="(item, index) in listData.polluted_sources[0].number_compliance_point_data" :key="index">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Lulus Uji Kontektifitas</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ item.technical_requirement.connectivity_test_type }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nomor Surat Lulus Uji Konektivitas</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  item.technical_requirement.connectivity_test_number }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Surat Lulus Uji Konektivitas</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="item.technical_requirement.certificate_connectivity_test" target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              item.technical_requirement.certificate_connectivity_test === "" ||
                              item.technical_requirement.certificate_connectivity_test === undefined ||
                              item.technical_requirement.certificate_connectivity_test === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Penyedia Barang dan Jasa</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ item.technical_requirement.connectivity_test_vendor }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Kontrak Kerjasama</strong>
                        </div>
                        <div class="col-lg-9">
                          <a
                            :href=" item.technical_requirement.cooperative_contract_vendor"
                            target="blank"
                          >
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              item.technical_requirement.cooperative_contract_vendor === "" ||
                              item.technical_requirement.cooperative_contract_vendor === undefined ||
                              item.technical_requirement.cooperative_contract_vendor === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Lulus Uji Konektivitas</strong>
                        </div>
                        <div class="col-lg-9">
                          <a
                            :href="item.technical_requirement.certificate_connectivity_test"
                            target="blank"
                          >
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              item.technical_requirement.certificate_connectivity_test === "" ||
                              item.technical_requirement.certificate_connectivity_test === undefined ||
                              item.technical_requirement.certificate_connectivity_test === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Hasil Kalibrasi Saat Awal Pemasangan Alat</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="item.technical_requirement.certificate_calibration_initial" target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              item.technical_requirement.certificate_calibration_initial === "" ||
                              item.technical_requirement.certificate_calibration_initial === undefined ||
                              item.technical_requirement.certificate_calibration_initial === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Hasil Kalibrasi Alat Rutin</strong>
                        </div>
                        <div class="col-lg-9">
                          <a
                            :href="item.technical_requirement.certificate_calibration_rountine" target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              item.technical_requirement.certificate_calibration_rountine === "" ||
                              item.technical_requirement.certificate_calibration_rountine === undefined ||
                              item.technical_requirement.certificate_calibration_rountine === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>
                            Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk Oleh
                            KLHK
                          </strong>
                        </div>
                        <div class="col-lg-9">
                          <a
                            :href="item.technical_requirement.certificate_calibration_lab" target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              item.technical_requirement.certificate_calibration_lab === "" ||
                              item.technical_requirement.certificate_calibration_lab === undefined ||
                              item.technical_requirement.certificate_calibration_lab === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Surat Dukungan Dari Brand Sensor</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="item.technical_requirement.certificate_calibration_brand"  target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              item.technical_requirement.certificate_calibration_brand === "" ||
                              item.technical_requirement.certificate_calibration_brand === undefined ||
                              item.technical_requirement.certificate_calibration_brand === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Surat Pernyataan</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="item.technical_requirement.statement_letter" target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>{{
                              item.technical_requirement.statement_letter === "" ||
                              item.technical_requirement.statement_letter === undefined ||
                              item.technical_requirement.statement_letter === null
                                ? " Belum ada  File Terupload"
                                : " File Terupload"
                            }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>

        <!-- Data Logger DONE -->
        <b-col xl="12">
          <b-card>
            <template v-slot="header">
              <h6 class="surtitle">Overview</h6>
              <h5 class="h3 mb-0">Data Logger</h5>

              <div class="card" style="background-color: #eaeaea;">
                <div class="card-body" v-for="(item, index) in listData.polluted_sources[0].number_compliance_point_data" :key="index">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Brand</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ item.data_logger.brand }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Model</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ item.data_logger.model }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Serial Number</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ item.data_logger.serial_number }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>MAC Address</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ item.data_logger.mac_address }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>

        <!-- Sensor DONE -->
        <b-col xl="12">
          <b-card>
            <template v-slot="header">
              <h6 class="surtitle">Overview</h6>
              <h5 class="h3 mb-0">Sensor</h5>

              <div class="card" style="background-color: #eaeaea;">
                <div class="card-body">
                  <div v-for="(item, index) in listData.polluted_sources[0].number_compliance_point_data " :key="index">
                    <ul class="list-group mb-5">
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-1">
                            <strong>{{ item.name}}</strong>
                          </div>
                          <div class="col-lg-11">
                            <template v-for="(items, index) in item.data_logger.sensor">
                              <div style="margin-bottom: 100px;">
                                <ul class="list-group">
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <h2>{{ items.name }}</h2>
                                      </div>
                                      <div class="col-10">{{ items.brand }}</div>
                                    </div>
                                  </li>
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <strong>Brand</strong>
                                      </div>
                                      <div class="col-10">{{ items.brand }}</div>
                                    </div>
                                  </li>
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <strong>Probe</strong>
                                      </div>
                                      <div class="col-10">{{ items.probe }}</div>
                                    </div>
                                  </li>
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <strong>Brosur</strong>
                                      </div>
                                      <div class="col-10">
                                        <a :href="items.brochure" target="blank">
                                          <span>
                                            <i
                                              class="ni ni-cloud-download-95 text-primary"
                                            ></i>
                                          </span>
                                          <span>{{
                                            items.brochure === "" ||
                                            items.brochure === undefined ||
                                            items.brochure === null
                                              ? " Belum ada  File Terupload"
                                              : " File Terupload"
                                          }}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <strong>Range Pengukuran</strong>
                                      </div>
                                      <div class="col-10">
                                        <div class="row">
                                          <div class="col-6">
                                            <div class="row">
                                              <div class="col-12">
                                                <strong>Min</strong>
                                              </div>
                                              <div class="col-12">
                                                {{ items.range_min}}
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div class="row">
                                              <div class="col-12">
                                                <strong>Max</strong>
                                              </div>
                                              <div class="col-12">
                                                {{ items.range_max }}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <strong>Baku Mutu Air Limbah</strong>
                                      </div>
                                      <div v-if="items.name === 'pH'" class="col-10">
                                        <div class="row">
                                          <div class="col-6">
                                            <div class="row">
                                              <div class="col-12">
                                                <strong>Min</strong>
                                              </div>
                                              <div class="col-12">{{ items.quality_standard_min }}</div>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div class="row">
                                              <div class="col-12">
                                                <strong>Max</strong>
                                              </div>
                                              <div class="col-12">{{ items.quality_standard_max }}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
          
                                      <div v-else class="col-10">
                                        <div class="row">
                                          <div class="col-12">
                                            <div class="row">
                                              <div class="col-12">
                                                <strong>Max</strong>
                                              </div>
                                              <div class="col-12">
                                                {{ items.max }}
                                                <span v-if="items.name ==='Debit'">{{ 'DUMMY' }}</span>
                                                <span v-else>mg/L</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
          
                                    </div>
                                  </li>
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <strong>Metode Pengukuran Sensor</strong>
                                      </div>
                                      <div class="col-10">{{ items.calibration_method }}</div>
                                    </div>
                                  </li>
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <strong>Jadwal Kalibrasi</strong>
                                      </div>
                                      <div class="col-10">{{ items.calibration_schedule }}</div>
                                    </div>
                                  </li>
                                  <li class="list-group-item">
                                    <div class="row">
                                      <div class="col-2">
                                        <strong>Metode Kalibrasi</strong>
                                      </div>
                                      <div class="col-10">{{ items.calibration_method }}</div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </template>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>



    

    

  </div>
</template>
<script>
import API from "../../../api/base_url.js"
import BaseHeader from "@/components/BaseHeader";
import moment from 'moment'
import Modal from "@/components/Modal.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
// import ValidationHistory from "./SparingValidationHistory.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";


export default {
  components: {
    BaseHeader,
    Modal,
    HtmlEditor,
    // ValidationHistory
  },
  data() {
    return {
      isLoading:true,
      isLoadingData: false,
      histories: [],
      verifyNote:"",
      showAnnoncementModal: false,
      showAnnoncementModalHistory:false,
      showAnnoncementModalValidation:false,
      listData: {}
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    accSparing(command) {
      console.log("Acc");
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      let payload = {
        message: this.verifyNote,
        status:command
      }

      API.put(`web/master/validation/sparing/validate?uid=${this.$route.params.id}` , payload, {headers})
      .then(({data:content}) => {
        console.log(content.data);
        if (command == 'VA') {
          Swal.fire('Validasi Berhasil diterima', 'Data ini sudah divalidasi', 'success')
        } else {
          Swal.fire('Validasi Berhasil ditolak', 'Data ini tidak lulus dalam proses validasi', 'success')
        }
        this.verifyNote = ""
        this.showAnnoncementModalValidation = false
      })
      .catch((err) => {
        console.log(err);
        Swal.fire('Validasi Gagal', 'Data gagal diverifikasi', 'error')
      })
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getData() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`web/master/validation/sparing/detail?uid=${this.$route.params.id}` , {headers})
      .then(({data:content}) => {
        console.log(content.data,'UHUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU');
        this.listData = content.data
        this.isLoading = false
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getValidationHistory() {
      console.log("getValidation History ini yak");

      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`web/master/validation/sparing/detail?uid=${this.$route.params.id}` , {headers})
      .then(({data:content}) => {
        console.log(content.data.log,'---STRONG>');
        for (let i = 0; i < content.data.log.log_history.length; i++) {
          this.histories.push({
            id:i+1,
            name: "apa hayoo",
            companyName:content.data.log.log_history[i].user_request,
            keterangan: content.data.log.log_history[i].message,
            created_time: this.formatDate(content.data.log.log_history[i].created_at),
            updatedAt:this.formatDate(content.data.log.log_history[i].updated_at),
            user_id:'admin',
            status:content.data.log.log_history[i].status
          })
        }
      })
      .catch((err) => {
        this.histories = []
        console.log(err);
      })

      // this.histories = [
      //   {
      //     id:1,
      //     name:'PT.Apa ni',
      //     companyName:"pt.sk lagi ya",
      //     keterangan: 'Validasi diterima pada tanggal 28 September 2020. Silakan cetak TTE melalui login akun Sparing fitur Profil Perusahaan.',
      //     created_time:'2022-01-01',
      //     updatedAt:'2022-01-01',
      //     documentName:'andica',
      //     user_id:'admin',
      //     status:'Accept',
      //   },
      //   {
      //     id:2,
      //     name:'PT.Apa ni',
      //     companyName:"pt.sk lagi ya",
      //     keterangan: 'Validasi diterima pada tanggal 28 September 2020. Silakan cetak TTE melalui login akun Sparing fitur Profil Perusahaan.',
      //     created_time:'2022-01-01',
      //     updatedAt:'2022-01-01',
      //     documentName:'andica',
      //     user_id:'admin',
      //     status:'Accept',
      //   },
      // ]

    return this.histories
    },
    getChangesData() {
    console.log("Change Data");
    this.histories = [
      {
        id:1,
        no:1,
        name:'TES COBA ni',
        updatedAt:'12-10-2007',
        changes:['Name','Name'],
      },
      {
        id:2,
        no:2,
        name:'ini dua',
        updatedAt:'12-10-2010',
        changes:['address','address'],
      },
  ]
    return this.histories

    },
    getChangesDocument() {
    console.log("document");
    
    this.histories =  [{
          no: 1,
          documentName: 'doc.nama_dokumen',
          documentType: 'doc.tipe_dokumen',
          updatedAt: 'doc.updated_time',
          oldDocument: 'doc.path',
        }
      ]

        return this.histories
    },
    getBackData(type) {
      if (type === 'validasi') {
          console.log("VALIDASI REQUEST DATA");
          this.getValidationHistory()
      }

      if (type === 'perubahanData') {
          console.log("PERUBAHAN DATA REQUEST DATA");
          this.getChangesData()
      }

      if (type === 'perubahanDokumen') {
          console.log("PERUBAHAN DOKUMEN REQUEST DATA");
          this.getChangesDocument()
      }
    },
    back() {
      this.$router.push("/admin-sparing");
    },
    seeTte() {
      this.showAnnoncementModal = true
    },
    seeRiwayatValidasi() {
      this.getBackData('validasi')
      this.showAnnoncementModalHistory = true
    },
    seeValidasi() {
      console.log("clicked");
      this.getBackData('validasi')
      this.showAnnoncementModalValidation = true
    }
  },
};
</script>
<style scoped>

.mdl-btn {
border-radius: 4px;
padding: 6px 12px;
border: transparent;
color: #ffffff;
width: 50%;
margin-right: 0;
}
.mdl-btn-accept {
background-color: #3D9973;

}
.mdl-btn-reject {
background-color: #EB5757;
}

.mdl-title {
font-size: 18px;
font-weight: 600;
color: #6C757D;
width: 100%;
}
.mdl-contents {
overflow-y: auto;
max-height: 90%;
}
.mdl-header {
height: 2.5rem;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
column-gap: 1rem;
}
.mdl-header i {
font-size: 30px;
color: #00bcd4;
color: #FFFFFF;
}
.mdl-header p {
margin-bottom: 0;
font-size: 28px;
font-weight: 600;
text-transform: uppercase;
color: #000000;
}

.announcement-modal-backgrounds {
background: #ff9999;
}

</style>
